import { Link, useParams } from 'react-router-dom';

// Import Images
import bnrImg1 from '../../images/banner/img1.jpg';
import { ReactComponent as HomeIcon } from '../../images/icon/home-icon.svg';
import { useState, useEffect } from 'react';
import { portfolioContent } from '../../data-service/portfolio-video/functions';
// Team Content

// Import your CSS module
import styles from './test.css';

function VideoPortfolio() {
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
  }

  const [selectedCategory, setSelectedCategory] = useState(null);
  const { height, width } = useWindowDimensions();
  const { primaryCategory } = useParams();

  useEffect(() => {
    if (primaryCategory) {
      setSelectedCategory(
        portfolioContent.find((category) => {
          return category.slug === primaryCategory;
        })
      );
    }
  }, [primaryCategory]);

  return (
    <>
      <div className="page-content bg-white">
        <div className="banner-wraper">
          <div
            className="page-banner"
            style={{ backgroundImage: 'url(' + bnrImg1 + ')' }}
          >
            <div className="container">
              <div className="page-banner-entry text-center">
                <h1>{selectedCategory?.title}</h1>
                <nav aria-label="breadcrumb" className="breadcrumb-row">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">
                        <HomeIcon />
                        Home
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {selectedCategory
                        ? selectedCategory?.title
                        : 'Video Portfolio'}
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.fullscreenIframe}>
        <iframe
          src={selectedCategory?.iFrameLink}
          width={width}
          height={height}
          content="width=device-width, initial-scale=1.0"
          title="Vimeo Pro Portfolio"
          className={styles.responsiveIframe}
          allowFullScreen
        ></iframe>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '64px',
          marginBottom: '64px',
        }}
      >
        <h2
          style={{
            textAlign: 'center',
            marginBottom: '32px',
          }}
        >
          Like what you see? <br />
        </h2>

        <Link to="/contact-us" className="btn btn-secondary btn-lg shadow">
          Get in Touch{' '}
        </Link>
      </div>
    </>
  );
}

export default VideoPortfolio;
