import React, { Component } from "react";

import {
  primaryColor,
  secondaryColor,
} from "../../../data-service/backend-functions/helpers";
import AIContentBlock from "./ai-content-block";

class AISection extends Component {
  render() {
    return (
      <>
        <section
          className="section-sp1 about-area"
          style={{
            marginTop: "64px",
          }}
        >
          {" "}
          <AIContentBlock
            title="Core AI Model"
            paragraph="Biotech-Specific Functionality: Tailored for biotech
                    challenges, this assistant is the foundation for your
                    innovative journey. "
            spanText=" Ex: Dr. Watson is asking about the clinical trial data.
            Write an email to him."
            svgPath={"/resources/svgs/Livechat.svg"}
            iconName={"fa fa-clock"}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            isReversed={false}
            feature={"Save 1-2 hours per day"}
            offeringType={"Core Offering"}
          />
          <AIContentBlock
            title="CRM Integration"
            paragraph="Seamless Data Integration: Connects with major CRM platforms, such as Salesforce, HubSpot, and Monday.com, for enhanced data utilization and customer insights."
            spanText="Ex: When did we last speak to Dr. Watson?"
            svgPath={"/resources/svgs/CRM-integration.svg"}
            iconName={"fa fa-bolt"}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            isReversed={true}
            feature={"Access your CRM data in seconds"}
            offeringType={"Add-On"}
          />
          <AIContentBlock
            title="Report Generation"
            paragraph="Actionable Insights: Transforms CRM data into insightful reports for better decision-making."
            spanText="Ex: Analyze sales performance and customer feedback trends"
            svgPath={"/resources/svgs/report-making.svg"}
            iconName={"fa fa-magnifying-glass-chart"}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            isReversed={false}
            offeringType={"Add-On"}
            feature={"Make Data-Driven Decisions"}
          />
          <AIContentBlock
            title="Translation Add-On"
            paragraph={`Global Communication: This feature offers advanced multilingual support, essential for global operations. It goes beyond simple translation tools by grasping language details and cultural nuances, ensuring precise and natural communication in various languages.`}
            svgPath={"/resources/svgs/translation.svg"}
            iconName={"fa fa-globe"}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            isReversed={true}
            feature={"Avoid Miscommunication"}
            offeringType={"Add-On"}
          />
          <AIContentBlock
            title="Platform Integration"
            paragraph={`Unified Communication: Integrates with platforms like Slack, WhatsApp for seamless team collaboration.`}
            svgPath={"/resources/svgs/platform-integration.svg"}
            iconName={"fa fa-arrows-turn-to-dots"}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            isReversed={false}
            feature={"Cross-Platform Accessibility"}
            offeringType={"Add-On"}
          />
          <AIContentBlock
            title="Operational Automation"
            paragraph={`Efficiency at Work: Automates routine tasks to boost productivity and focus on strategic initiatives.`}
            svgPath={"/resources/svgs/Task-automation.svg"}
            iconName={"fa fa-wand-magic-sparkles"}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            isReversed={true}
            feature={"Seamless Operational Integration"}
            offeringType={"Add-On"}
          />
          {/* <img className="pt-img1 animate-wave" src={ptImg1} alt=""/> */}
        </section>
      </>
    );
  }
}

export default AISection;
