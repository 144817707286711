const hcpTypes = [
  { id: 'anesthesiologists', label: 'Anesthesiologists' },
  { id: 'cardiologists', label: 'Cardiologists' },
  {
    id: 'critical care medicine physicians',
    label: 'Critical Care Medicine Physicians',
  },
  { id: 'dermatologists', label: 'Dermatologists' },
  {
    id: 'emergency medicine physicians',
    label: 'Emergency Medicine Physicians',
  },
  { id: 'endocrinologists', label: 'Endocrinologists' },
  { id: 'family medicine physicians', label: 'Family Medicine Physicians' },
  { id: 'gastroenterologists', label: 'Gastroenterologists' },
  { id: 'general practice physicians', label: 'General Practice Physicians' },
  {
    id: 'geriatric medicine physicians',
    label: 'Geriatric Medicine Physicians',
  },
  { id: 'hematologists', label: 'Hematologists' },
  { id: 'hospitalists', label: 'Hospitalists' },
  { id: 'internal medicine physicians', label: 'Internal Medicine Physicians' },
  { id: 'neurologists', label: 'Neurologists' },
  { id: 'ob/gyn physicians', label: 'OB/GYN Physicians' },
  { id: 'oncologists', label: 'Oncologists' },
  { id: 'orthopedists', label: 'Orthopedists' },
  { id: 'otolaryngologists', label: 'Otolaryngologists' },
  { id: 'pathologists', label: 'Pathologists' },
  { id: 'pediatricians', label: 'Pediatricians' },
  { id: 'physical medicine physicians', label: 'Physical Medicine Physicians' },
  { id: 'plastic surgeons', label: 'Plastic Surgeons' },
  { id: 'psychiatrists', label: 'Psychiatrists' },
  { id: 'pulmonologists', label: 'Pulmonologists' },
  { id: 'radiologists', label: 'Radiologists' },
  { id: 'rheumatologists', label: 'Rheumatologists' },
  { id: 'surgeons', label: 'Surgeons' },
  { id: 'urologists', label: 'Urologists' },
];

export const HCPMarketingDetailsSection = () => (
  <section className="section-sp2 hcp-details">
    <div className="container">
      <div className="row align-items-center">
        <div className="text-center col-md-10 col-sm-12  mx-auto">
          <h3 className="title-ext text-primary">
            Get Access to over 100 different types of HCPs
          </h3>
          <h6
            className="lh-base mt-40 mb-50 "
            style={{
              fontWeight: '400',
              color: 'var($primary)',
            }}
          >
            Our team partners with premier data providers, which allows us to
            build custom NPI lists based on your custom HCP audience while
            remaining compliant with privacy policies. Here are the most popular
            HCP specialities but other HCP specialities and sub-speciliaties are
            available:
          </h6>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="row row-cols-auto gy-3 justify-content-center">
        {hcpTypes.map((item) => (
          <div className="" key={item.id}>
            <div className="feature-container feature-bx1 feature1">
              <div className="icon-md">
                <span className="icon-cell"></span>
              </div>
              <div className="icon-content">
                <h4 className="ttr-title">{item.label}</h4>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="row mt-30 text-center">
        <p>And Many More</p>
      </div>
    </div>
    {/* <img className="pt-img1 animate-wave" src={ptImg1} alt=""/> */}
  </section>
);
