import React, { Component } from 'react';

// Import Images
import hcpBannerImg from '../../../images/banner/hcp-marketing-banner.jpg';

class HCPMarketingBanner extends Component {
  render() {
    return (
      <div className="conference-hero">
        <div className="container inner-content">
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-6 col-md-6 col-sm-7">
              <h1>HCP Marketing</h1>
              <h3 className="title-ext text-primary">
                Digital Marketing and Advertising to HCPs Using NPI Numbers 
              </h3>
              <h6
                className="lh-base"
                style={{
                  fontWeight: '400',
                  color: 'var($primary)',
                }}
              >
                Our media team believes in harnessing the power of NPI numbers
                and claims data to create hyper-targeted digital advertising and
                marketing campaigns targeting HCPs.
              </h6>
            </div>

            <div className="col-lg-5 col-md-6 col-sm-5">
              <div
                className="banner-img"
                style={{
                  marginLeft: 'auto',
                }}
              >
                <img
                  src={hcpBannerImg}
                  style={{
                    width: '100%',
                    objectFit: 'contain',
                    borderRadius: '10px',
                  }}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HCPMarketingBanner;
