import React, { Component } from "react";
import { Link } from "react-router-dom";

// Elements
import LatestNewsSection from "../elements/latest-news-slider";
import { AIInsightsDetail } from "../elements/ai-section/detail";
// Import Images
import AISection from "../elements/ai-section/about-section";
import { ContactSection } from "../elements/contact-section";
import { primaryColor, secondaryColor } from "../../data-service/backend-functions/helpers";

class AIInsights extends Component {
  render() {
    return (
      <>
        <div className="page-content bg-white">
          <div
            className="banner-wraper"
            style={{
              maxHeight: "600px",
            }}
          >
            <div className="main-banner">
              <div className="container inner-content">
                <div className="row align-items-center">
                  <div className="col-lg-7 col-md-6 col-sm-7">
                    <h1 className="title-ext text-primary">
                      Advanced AI Solutions for Biotech
                    </h1>
                    <h2>
                      Innovative, compliant AI solutions for biotech ventures.
                    </h2>
                    <p style={{
                      marginTop: "32px",
                      marginBottom: "32px",
                      fontWeight: "400",
                      color: "black",
                      fontSize: "20px",
                      
                    
                    }}>
                      Our AI Solutions are built in-house to enhance biotech
                      ventures, embodying a strong commitment to compliance and
                      fostering innovation.
                    </p>

                    <div
                      className="row-lg-5 row-md-6 row-sm-5"
                      style={{
                        marginTop: "48px",
                      }}
                    >
                     
                      <Link
                        to="https://calendly.com/kurtgreves/60-minute-meeting"
                        className="btn btn-primary btn-lg shadow"
                      >
                        Schedule a Demo
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-6 col-sm-5"></div>
                  {/* Blue dIVIDER HORIZONTAL */}
                  <div
                    style={{
                      width: "100%",
                      height: "4px",
                      //gradient
                       backgroundImage: `linear-gradient(to right, ${primaryColor}, ${secondaryColor})`,
                      marginTop: "10vh",
                      marginBottom: "32px",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <AISection />
          <AIInsightsDetail />
          <ContactSection />
          <LatestNewsSection />
        </div>
      </>
    );
  }
}

export default AIInsights;
