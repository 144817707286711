import React, { useContext, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import { ReactComponent as HomeIcon } from '../../images/icon/home-icon.svg';

import { WebinarContext } from '../../data-service/webinars/webinar-context';

function WebinarDetails(props) {
  const webinarContext = useContext(WebinarContext);
  const { webinarID } = useParams();

  const { selectedWebinar } = webinarContext;
  useEffect(() => {
    webinarContext.fetchWebinarBySlug(webinarID);
  });

  if (!selectedWebinar) return <></>;

  return (
    <>
      <div className="page-content bg-white">
        <div className="banner-wrapper">
          <div
            className="page-banner"
            style={{ backgroundImage: 'url(' + bnrImg1 + ')' }}
          >
            <div className="container">
              <div className="page-banner-entry text-center">
                <h1
                  style={{
                    marginTop: '2rem',
                    fontSize: '2.0rem',
                  }}
                >
                  {selectedWebinar?.title ?? ''}
                </h1>
                <nav aria-label="breadcrumb" className="breadcrumb-row">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">
                        <HomeIcon /> Home
                      </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/webinars">DigiVidBIO Webinars</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {selectedWebinar?.title ?? ''}
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>

        <section className="section-area section-sp1 bg-white">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mb-30 mb-md-50">
                <div className="blog-card blog-single">
                  <div>
                    <img
                      style={{
                        width: '100%',
                        marginBottom: '40px',
                      }}
                      src={selectedWebinar?.featuredImage?.url ?? ''}
                      alt=""
                    />
                  </div>
                  {selectedWebinar !== null ? (
                    <>
                      {/* render iframe with webinar link */}
                      <iframe
                        src={selectedWebinar.iFrameUrl}
                        width="100%"
                        height="500px"
                        title={selectedWebinar.title}
                      ></iframe>
                      {!!selectedWebinar?.description?.length && (
                        <div className="row justify-content-center">
                          <div className="col-lg-8 mt-30 mb-30">
                            <div className="heading-bx">
                              <h4
                                style={{
                                  fontWeight: 400,
                                  whiteSpace: 'pre-wrap',
                                }}
                              >
                                {selectedWebinar.description}
                              </h4>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <div>loading...</div>
                  )}
                </div>

                <div className="clear" id="comment-list">
                  <div className="comments-area" id="comments"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default WebinarDetails;
