import React from 'react';

import { ContactSection } from '../elements/contact-section';
import HCPMarketingBanner from '../elements/hcp-marketing/main-banner';
import { HCPMarketingDetailsSection } from '../elements/hcp-marketing/details';
import HCPServicesSliderSection from '../elements/hcp-marketing/services-slider';

function HCPMarketing() {
  return (
    <>
      <HCPMarketingBanner />
      <HCPServicesSliderSection />
      <HCPMarketingDetailsSection />

      <ContactSection />
    </>
  );
}

export default HCPMarketing;
