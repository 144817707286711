
import React from "react";


import LatestNewsSection from "../elements/latest-news-slider";
import { ContactSection } from "../elements/contact-section";
import AestheticMarketingBanner from "../elements/aesthetic-conference-marketing/main-banner";
import { AestheticMarketingDetail } from "../elements/aesthetic-conference-marketing/detail";
import AestheticMarketingServicesDetails from "../elements/aesthetic-conference-marketing/services-slider";
function AestheticConferenceMarketing() {
  return (
    <>
      <AestheticMarketingBanner />
      {AestheticMarketingDetail}

      <AestheticMarketingServicesDetails />

      <LatestNewsSection />
      <ContactSection />
    </>
  );
}

export default AestheticConferenceMarketing;
