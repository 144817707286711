import React from 'react';

import LatestNewsSection from '../elements/latest-news-slider';
import { ContactSection } from '../elements/contact-section';
import RSNAAmplificationStrategiesBanner from '../elements/rsna-amplification-strategies/main-banner';
import { RSNAAmplificationStrategiesDetail } from '../elements/rsna-amplification-strategies/detail';
import RSNAAmplificationStrategiesServicesDetails from '../elements/rsna-amplification-strategies/services-slider';

function RSNAAmplificationStrategies() {
  return (
    <>
      <RSNAAmplificationStrategiesBanner />
      {RSNAAmplificationStrategiesDetail}

      <RSNAAmplificationStrategiesServicesDetails />

      <LatestNewsSection />
      <ContactSection />
    </>
  );
}

export default RSNAAmplificationStrategies;
