import React from 'react';
import tempusLogo from '../../../images/logos/tempus.png';
import hologicLogo from '../../../images/logos/hologic.jpg';
import iCadMedicalLogo from '../../../images/logos/i-cad-medical.png';
import RADFormation from '../../../images/logos/rad-ormation.png';
import ferrumHealthLogo from '../../../images/logos/ferrum-health.jpg';

import './index.scss';
export const CustomerSection = () => {
  return (
    <div className="customer_section">
      <h2
        style={{
          fontWeight: 'normal',
        }}
      >
        Our Clients
      </h2>
      <div style={{ height: '48px' }} />
      <div className="customer_section_logo_section">
        <div className="customer_section_logo_sub_section">
          <img
            src={tempusLogo}
            alt="Tempus AI"
            className="customer_section_brand_logo"
            style={{
              objectFit: 'contain',
            }}
          />
          <img
            src={hologicLogo}
            alt="Hologic"
            className="customer_section_brand_logo"
            style={{
              objectFit: 'contain',
            }}
          />
        </div>
        <div className="customer_section_logo_sub_section">
          <img
            src={iCadMedicalLogo}
            alt="iCad Medical"
            className="customer_section_brand_logo"
            style={{
              objectFit: 'contain',
            }}
          />
          <img
            src="https://media.graphassets.com/output=format:jpg/resize=height:800,fit:max/ZKOtJtBQ431UgtCWFVFg"
            className="customer_section_brand_logo"
            alt="Agilent Technologies"
          />
        </div>
        <div className="customer_section_logo_sub_section">
          <img
            src={RADFormation}
            alt="RAD Formation"
            className="customer_section_brand_logo"
            style={{
              objectFit: 'contain',
            }}
          />
          <img
            src={ferrumHealthLogo}
            alt="Ferrum Health"
            className="customer_section_brand_logo"
            style={{
              objectFit: 'contain',
            }}
          />
        </div>
      </div>{' '}
      <div style={{ height: '64px' }} />
    </div>
  );
};
