import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { ReactComponent as VideoCameraIcon } from '../../../images/icon/targeting.svg';
import { ReactComponent as AIIcon } from '../../../images/icon/people.svg';
import { ReactComponent as ConferenceMarketing } from '../../../images/icon/traffic.svg';

const renderPrecisionTargetingSlide = () => (
  <div className="slider-item">
    <div className="services-card">
      <div className="feature-container feature-bx2 feature1">
        <div className="feature-box-xl mb-30">
          <span className="icon-cell">
            <VideoCameraIcon />
          </span>
        </div>
        <div className="icon-content">
          <h3 className="ttr-title">Precision Targeting</h3>
          <p>
            We drill down to the individual HCP with NPI targeting to reach your
            niche audience whenever they’re consuming media, across all channels
            and devices.
          </p>
        </div>
      </div>
    </div>
  </div>
);

const renderNicheCampaignsSlide = () => (
  <div className="slider-item">
    <div className="services-card">
      <div className="feature-container feature-bx2 feature2">
        <div className="feature-box-xl mb-20">
          <span className="icon-cell">
            <ConferenceMarketing />
          </span>
        </div>
        <div className="icon-content ">
          <h3 className="ttr-title">Niche Campaigns</h3>
          <p>
            No matter how niche your audience, we can create a digital campaign
            to reach, engage and convert. Need to reach Oncologists?
            Nephrologists? Lab Management at academic institutions? No problem!
          </p>
        </div>
      </div>
    </div>
  </div>
);

const renderOptimizeMetricsSlide = () => (
  <div className="slider-item">
    <div className="services-card">
      <div className="feature-container feature-bx2 feature3">
        <div className="feature-box-xl mb-20">
          <span className="icon-cell">
            <AIIcon />
          </span>
        </div>
        <div className="icon-content">
          <h3 className="ttr-title">Optimized Metrics</h3>
          <p>
            Our HCP NPI and billing code campaigns provide detailed metrics
            optimized to your KPIs to get the results you’ve been wanting to
            achieve.
          </p>
        </div>
      </div>
    </div>
  </div>
);

class HCPServicesSliderSection extends Component {
  render() {
    const settings = {
      dots: true,
      autoplay: true,
      duration: 500,

      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 591,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };

    return (
      <>
        <section className="section-area section-sp1 service-wraper-main-page">
          <div className="row align-items-center">
            <div className="col-xl-4 col-lg-6 mb-30">
              <div className="heading-bx">
                <h6 className="title-ext text-secondary">Our Advantages</h6>
                <h2 className="title">
                  Digital Marketing and Advertising to HCPs
                </h2>
                <p className="fw-normal">
                  Learn more about unlocking the secrets of NPI audience
                  targeting in our exclusive <Link to="/webinars">webinar</Link>{' '}
                  series.
                </p>
              </div>
            </div>
            <div className="col-xl-8 mb-15">
              <Slider {...settings} className="service-slide slick-arrow-none">
                {renderPrecisionTargetingSlide()}
                {renderNicheCampaignsSlide()}
                {renderOptimizeMetricsSlide()}
                {renderPrecisionTargetingSlide()}
                {renderNicheCampaignsSlide()}
                {renderOptimizeMetricsSlide()}
              </Slider>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default HCPServicesSliderSection;
