import { Image } from "react-bootstrap";

export const AIInsightsDetail = () => (
  <section className="section-sp1 about-area">
    <h1
      style={{
        textAlign: "center",
        marginBottom: "64px",
        marginLeft: "16px",
        marginRight: "16px",
      }}
    >
      Our Commitment
    </h1>
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6 mb-30">
          <div className="heading-bx">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            ></div>
            <h6
              style={{
                color: "black",
                fontSize: "20px",
              }}
            >
              Our Unwavering Commitment
            </h6>
            <p
              style={{
                marginTop: "32px",
                marginBottom: "32px",
                fontWeight: "400",
                color: "black",
                fontSize: "20px",
                lineHeight: "32px",
              }}
            >
              We prioritize data security and compliance in biotech. Our
              commitment goes beyond technology, encompassing robust support and
              continuous innovation to meet and surpass biotech demands. We
              focus on providing advanced solutions for operational efficiency
              and improved ROI.
            </p>
          </div>
          <div className="row">
            <div className="col-lg-6 col-sm-6 mb-30 mb-sm-20">
              <div className="feature-container feature-bx1 feature1">
                <div className="icon-md">
                  <span className="icon-cell"></span>
                </div>
                <div className="icon-content">
                  <h4 className="ttr-title">Biotech Specific Solutions</h4>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6 mb-30 mb-sm-20">
              <div className="feature-container feature-bx1 feature2">
                <div className="icon-md">
                  <span className="icon-cell"></span>
                </div>
                <div className="icon-content">
                  <h4 className="ttr-title">Seamless Integration</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-sm-6 mb-30 mb-sm-20">
              <div className="feature-container feature-bx1 feature1">
                <div className="icon-md">
                  <span className="icon-cell"></span>
                </div>
                <div className="icon-content">
                  <h4 className="ttr-title">Boost ROI</h4>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6 mb-30 mb-sm-20">
              <div className="feature-container feature-bx1 feature2">
                <div className="icon-md">
                  <span className="icon-cell"></span>
                </div>
                <div className="icon-content">
                  <h4 className="ttr-title">Marketing Automation</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 mb-30">
          <Image
            style={{
              width: "100%",
              borderRadius: "16px",
            }}
            src={
              "https://www.food-safety.com/ext/resources/eDigest/2023/GettyImages-861911406-900.jpg?1696345509"
            }
            alt="A man in a lab coat"
          />
        </div>
        <div className="conference-marketing-bx">
          <div className="conference-marketing-content">
            <h2>Robust Compliance and Secure Data Management</h2>
            <p
              style={{
                color: "white",
                marginTop: "32px",
              }}
            >
              Prioritizing Trust and Security in AI: Emphasizing robust
              compliance with HIPAA and industry standards, our AI models are
              engineered to offer the highest level of data security, ensuring
              reliable and ethical management of sensitive biotech data.
            </p>
          </div>
          <div
            style={{
              width: "32px",
            }}
          ></div>
          <div className="conference-marketing-bx-image">
            <Image
              src={
                "https://www.analyticsinsight.net/wp-content/uploads/2021/01/Data-Centers-1024x576.jpeg"
              }
              alt="Server"
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        ></div>
      </div>
    </div>
    {/* <img className="pt-img1 animate-wave" src={ptImg1} alt=""/> */}
  </section>
);
