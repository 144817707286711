import axios from "axios";
import { graphqlAPI } from "../blogs";
const getWebinarQuery = `query MyQuery {
    webinars {
      createdAt
      slug
      id
      publishedAt
      title
      updatedAt
      isFeatured
      description
      iFrameUrl
      postDate
    }
  }
  `;

export async function getWebinars() {
  try {
    return await axios({
      url: graphqlAPI,
      method: "post",
      data: {
        query: getWebinarQuery,
      },
    }).then((result) => {
      const data = result.data.data.webinars;
      console.log('webinars:', data);
      let posts = data;

      posts.sort(function (a, b) {
        var dateA = new Date(a.createdAt),
          dateB = new Date(b.createdAt);
        return dateB - dateA;
      });
      return posts;
    });
  } catch (e) {
    console.log(e);
  }

  console.log("set to true");
}
