import React from "react";
import Markup from "./markup/markup";

// Slick Carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Fonts
import "./vendor/line-awesome/css/line-awesome.min.css";
import "./vendor/font-awesome/css/all.min.css";
import "./vendor/themify/themify-icons.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

// StyleSheet
import "./App.scss";
import { BlogContextProvider } from "./data-service/blogs/blog-context";
import { VideoPortfolioProvider } from "./data-service/portfolio-video/video-portfolio-context";
import { CaseStudyProvider } from "./data-service/case-study/case-study";
import { WebinarContextProvider } from "./data-service/webinars/webinar-context";

function App() {
  library.add(fas);

  return (
    <div className="page-wraper">
      <CaseStudyProvider>
        <VideoPortfolioProvider>
          <WebinarContextProvider>
            <BlogContextProvider>
              <Markup />
            </BlogContextProvider>
          </WebinarContextProvider>
        </VideoPortfolioProvider>
      </CaseStudyProvider>
    </div>
  );
}

export default App;
