import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { isMobile } from "react-device-detect";

function AIContentBlock({
  title,
  paragraph,
  spanText,
  svgPath,
  iconName,
  primaryColor,
  secondaryColor,
  isReversed,
  feature,
  offeringType,
}) {
  return (
    <div
      className="container"
      style={{
        width: "100%",
        display: "flex",
      }}
    >
      <div
        className={`row align-items-center `}
        style={{
          flexDirection: isReversed ? "row-reverse" : "row",
          width: isMobile ? "100vw" : "100%",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: isMobile ? "96px" : "128px",
        }}
      >
        <div className="col-lg-4" style={{}}>
          <img src={svgPath} alt="SVG" />
        </div>
        <div
          className="col-lg-8 mb-30"
          style={{
            paddingRight: !isReversed ? "0px" : isMobile ? "16px" : "64px",
            paddingLeft: !isReversed ? (isMobile ? "16px" : "64px") : "0px",
            padding: isMobile ? "0px 16px" : "0px",
          }}
        >
          <div className="heading-bx">
            <div
              style={{
                padding: "8px 16px",
                width: "max-content",
                backgroundColor: isReversed ? primaryColor : secondaryColor,
                color: "white",
                fontWeight: "600",
                borderRadius: "16px",
                marginTop: "32px",
              }}
            >
              {offeringType}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                marginTop: "24px",
              }}
            >
              <h2
                className={`title-ext ${
                  isReversed ? "text-secondary" : "text-primary"
                }`}
              >
                {title}
              </h2>
            </div>
            <p
              style={{
                marginTop: isMobile ? "8px" : "32px",
                marginBottom: "32px",
                fontWeight: "400",
                color: "black",
                fontSize: isMobile ? "20px" : "24px",
                lineHeight: isMobile ? "32px" : "",
              }}
            >
              {paragraph}

              <span
                style={{
                  marginTop: "32px",
                  marginBottom: "32px ",
                  color: "black",
                  fontSize: isMobile ? "20px" : "24px",
                  lineHeight: isMobile ? "32px" : "",
                }}
              >
                <br />
                <br />
                {spanText}
                <br />
              </span>
            </p>
            <div
              style={{
                width: "100%",
                height: "4px",
                backgroundImage: `linear-gradient(to right, ${primaryColor}, ${secondaryColor})`,
                marginTop: "48px",
                marginBottom: "32px",
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  backgroundColor: primaryColor,
                  borderRadius: "50%",
                  height: "64px",
                  width: "64px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: "16px",
                }}
              >
                <FontAwesomeIcon
                  icon={iconName}
                  color="white"
                  style={{
                    height: "32px",
                    width: "32px",
                    margin: "16px",
                  }}
                />
              </div>
              <p
                style={{
                  fontSize: "20px",
                  fontWeight: "600",
                  marginLeft: "24px",
                  marginTop: "16px",
                }}
              >
                {feature}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AIContentBlock;
